import React, { useState, useEffect, useRef } from "react";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";

import TranslateToList from "./TranslateToList";
import QualityOutput from "./QualityOutput";
import { Link, navigate } from "gatsby";
import DetailAnalysis from "./DetailAnalysis";
import useDebounce from "./useDebounce";
import axios from "axios";
import { Languages } from "./LanguagesData";
import TranslateFromList from "./TranslateFormList";
import ResetButton from "assets/resetbutton.jpg";
import Toolinput from "components/commontools/InsertTool";

import ToolsBanner from "components/commontools/toolsBanner";
import ToolOutput from "components/commontools/ToolOutput";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import { parseResult } from "utils/toolsresult";
import { useLocation } from "@reach/router";
import Loader from "components/loader";
import CleanButton from "assets/cleanBtn.svg";

const QualityAnalysis = () => {
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [TransFromOpen, setTransFromOpen] = useState(false);
  const [TransToOpen, setTransToOpen] = useState(false);
  const [FromSearchInput, setFromSearchInput] = useState("");
  const [ToSearchInput, setToSearchInput] = useState("");
  const [sameLanguageError, setSameLanguageError] = useState(false);
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  const [showDetailAnalysis, setShowDetailAnalysis] = useState(false);
  const [detectedLanguage, setDetectedLanguage] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
  const refDiv = useRef(null);
  const TranslateFromRef = useRef(null);
  const debouncedInputText = useDebounce(inputText, 2000);
  const debouncedOutputText = useDebounce(outputText, 2000);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [wordCountOutput, setWordCountOutput] = useState(0);
  const [currentUrl, setCurrentUrl] = useState("");
  const [shareId, setShareId] = useState("");

  // const isProduction =
  //   process.env.GATSBY_ES_PREFIX === "en.prod."
  //     ? "https://api.machinetranslation.com"
  //     : "https://mtstage.bytequests.com";

  // console.log(isProduction, "isproduction");
  const { data } = useFetchLanguages();

  const TranslateFromstyles = {
    display: TransFromOpen ? "block" : "none",
  };
  const TranslateTostyles = {
    display: TransToOpen ? "block" : "none",
  };

  const handleTranslateFromInput = (lang, code) => {
    setInputLang(code);
    setTranslateFromInput(lang);
    setTransFromOpen(!TransFromOpen);
    setFromSearchInput("");
  };

  const handleTranslateToInput = (lang, code) => {
    setOutputLang(code);
    setTranslateToInput(lang);
    setTransToOpen(!TransToOpen);
    setToSearchInput("");
  };

  useEffect(() => {
    if (refDiv.current) {
      setDivHeight(refDiv.current.base.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectSourceLanguage(debouncedInputText);
    }
  }, [debouncedInputText]);
  useEffect(() => {
    if (debouncedOutputText?.trim()?.length >= 3) {
      detectTargetLanguage(debouncedOutputText);
    }
  }, [debouncedOutputText]);

  const detectSourceLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const language = response.data?.data?.language_probability?.code;
      const languageName = Languages.find((lang) => lang?.code === language);

      if (languageName?.name) {
        setInputLang(language);
      }

      const languageCode = response.data?.data?.language_probability?.name;
      setTranslateFromInput(languageCode);
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };
  const detectTargetLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const languageTo = response.data?.data?.language_probability?.code;
      const languageToName = Languages.find(
        (lang) => lang?.code === languageTo
      );
      if (languageToName?.name) {
        setOutputLang(languageTo);
      }
      const languageCode = response.data?.data?.language_probability?.name;

      setTranslateToInput(languageCode);
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };
  useEffect(() => {
    const path = window.location.pathname;
    setCurrentUrl(path);
  }, []);
  const handleClick = async () => {
    if (isButtonDisabled) return;

    setLoading(true);
    setError(false);
    setShowOutput(false);

    try {
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "translationQuality",
        source_text: inputText,
        translation: outputText,
        source_language_code: inputLang,
        target_language_code: outputLang,
      });

      // Set the share ID and update the URL
      const newShareId = response?.meta?.share_id;

      setShareId(newShareId);
 
      navigate(`/tools/translation-quality-assurance?share-id=${newShareId}`)

      // Trigger the analysis fetching
      setShowOutput(true);
    } catch (error) {
      setError(error);
      // console.error("Error during quality analysis:", error);
      // setError(true);
    } finally {
      setLoading(false);
    }
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");

  const displayShareId = shareId ? shareId : UpdatreshareId;

  useEffect(() => {
    if (!displayShareId) return;
    const fetchAnalysis = async () => {
      setLoading(true);
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );

        setAnalysisResult(response);
        setShowOutput(true);

        const {
          source_language_code: sourceLangCode,
          target_language_code: targetLangCode,
          source_text: sourceText,
          translation_text: translationText,
        } = response.meta;

        setInputLang(sourceLangCode);
        setOutputLang(targetLangCode);
        setInputText(sourceText);
        setOutputText(translationText);

        const sourceLangName = Languages.find(
          (lang) => lang.code === sourceLangCode
        )?.name;
        setTranslateFromInput(sourceLangName || "");

        const targetLangName = Languages.find(
          (lang) => lang.code === targetLangCode
        )?.name;
        setTranslateToInput(targetLangName || "");
      } catch (err) {
        console.error("Error fetching analysis result:", err);
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchAnalysis();
  }, [displayShareId]);

  // if (loading) return <p>Loading...</p>;
  // if (error)
  //   return <p>Not Found: The requested analysis could not be found.</p>;

  const filteredFromData = Languages.filter((item) => item.code !== outputLang);
  const filteredToData = Languages.filter((item) => item.code !== inputLang);

  const isButtonDisabled =
    !transtalteFromInput || !transtalteToInput || !inputText || !outputText;
  const placeholderText = (
    <span className="absolute top-0 left-0 pl-4 py-4 text-[15px] text-[#B4B4B4] pointer-events-none">
      Enter your <span className="font-semiboldb text-[#ff0000]">source</span>{" "}
      text here
    </span>
  );

  const handleReset = async () => {
    await handleClick();
  };
  const handleSourceClearText = () => {
    if(UpdatreshareId){
    setInputText("");
    setOutputText("")
    setInputLang("");
    setOutputLang("")
    setTranslateToInput("");
    setTranslateFromInput("");
    setAnalysisResult(null);
    setShowOutput(false);
    setWordCountInput(0);
    setWordCountOutput(0);
    setInputLang("");
    setOutputLang("");
    setError("")
    navigate(`/tools/translation-quality-assurance/`);
  }
  setInputLang("")
  setInputText("")
  setWordCountInput(0);
  };
  const handleTargetClearText = () => {
    if(UpdatreshareId){
      setInputLang("")
    setOutputText("");
   setInputText("")
    setOutputLang("");
    setTranslateToInput("");
    setTranslateFromInput("");
    setAnalysisResult(null);
    setShowOutput(false);
    setWordCountInput(0);
    setWordCountOutput(0);
    setInputLang("");
    setOutputLang("");
    setError("")
    navigate(`/tools/translation-quality-assurance/`);
    }
    setOutputLang("")
    setOutputText("")
    setWordCountOutput(0);
  };
  
  return (
    <div className="bg-[#F5F5F5]">
      {showDetailAnalysis ? (
        <>
          <DetailAnalysis
            setShowDetailAnalysis={setShowDetailAnalysis}
            analysisResult={analysisResult.result}
          />
        </>
      ) : (
        <>
          {/* {loading && (
            <div className="flex items-center justify-center h-[100vh]">
              <Loader />
            </div>
          )}
          {error && (
            <div className="flex items-center justify-center h-[100vh]">
              <p className="text-center">{error}</p>
            </div>
          )}
          {!loading && !error && (
            <> */}
              <div className="py-7">
                <ToolsBreadcrumb />
              </div>
              <div className="max-w-7xl mx-auto px-4 py-12 lg:py-[60px]">
                <ToolsBanner
                  text="TRANSLATION QUALITY ASSURANCE TOOL"
                  title="Identify and Resolve Quality Issues Quickly"
                  description="  Enter your source text and its translation, and get detailed
              feedback on accuracy, fluency, and overall translation quality
              instantly."
                />
                <div className="lg:flex gap-3 mt-12">
                  <div className="flex flex-col gap-3 w-full lg:w-[70%]">
                    <div className="lg:flex items-center  rounded-lg shadow-lg ">
                      <Toolinput
                        lang={inputLang}
                        text={inputText}
                        wordCount={wordCountInput}
                        setText={setInputText}
                        setOutputText={setOutputText}
                        setInputLang={setInputLang}
                        setOutputLang={setOutputLang}
                        setTranslateToInput={setTranslateToInput}
                        setTranslateFromInput={setTranslateFromInput}
                        setAnalysisResult={setAnalysisResult}
                        setShowOutput={setShowOutput}
                        setWordCount={setWordCountInput}
                        iscleanBtn={true}
                        refDiv={refDiv}
                        result={analysisResult?.result}
                        divHeight={divHeight}
               
                        // placeholder="Enter your source text here"
                        placeholder="Enter your text here"
                      >
                        {/* {" "}
                        <TranslateFromList
                          data={{ data: filteredFromData }}
                          TranslateFromRef={TranslateFromRef}
                          TransFromOpen={TransFromOpen}
                          transtalteFromInput={transtalteFromInput}
                          setTransFromOpen={setTransFromOpen}
                          TranslateFromstyles={TranslateFromstyles}
                          FromSearchInput={FromSearchInput}
                          setFromSearchInput={setFromSearchInput}
                          handleTranslateFromInput={handleTranslateFromInput}
                          sameLanguageError={sameLanguageError}
                          divHeight={divHeight}
                        /> */}
                        <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                        <p className=" text-[#6E6E6E] font-opensans font-bold">
                          Source text
                        </p> 
                        {inputText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={handleSourceClearText}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                      </div>
                      </Toolinput>

                      <div className="hidden lg:block h-full border border-[#DEDEDE] "></div>

                      <Toolinput
                        lang={outputLang}
                        text={outputText}
                        wordCount={wordCountOutput}
                        setText={setOutputText}
                        setWordCount={setWordCountOutput}
                        refDiv={refDiv}
                        result={analysisResult?.result}
                        divHeight={divHeight}
                        isTranslation ={true}
                       
                        // placeholder="Enter your translated text here"
            
                        placeholder="Enter your text here"
                      >
                    
                        <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                        <p className=" text-[#6E6E6E] font-opensans font-bold">
                        Translation
                        </p> 
                        {outputText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={handleTargetClearText}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                      </div>
                        {/* <TranslateToList
                          data={{ data: filteredToData }}
                          TranslateToRef={TranslateFromRef}
                          TransToOpen={TransToOpen}
                          transtalteToInput={transtalteToInput}
                          setTransToOpen={setTransToOpen}
                          TranslateTostyles={TranslateTostyles}
                          ToSearchInput={ToSearchInput}
                          setToSearchInput={setToSearchInput}
                          handleTranslateToInput={handleTranslateToInput}
                          sameLanguageError={sameLanguageError}
                          divHeight={divHeight}
                        /> */}
                      </Toolinput>
                    </div>
                  </div>

                  <ToolOutput
                    handleClick={handleClick}
                    result={analysisResult?.result}
                    isButtonDisabled={isButtonDisabled}
                    ResetButton={ResetButton}
                    loading={loading}
                    handleReset={handleReset}
                    showOutput={showOutput}
                    error={error}
                    ref={refDiv}
                    buttontext="Check quality"
                    isQuality={true}
                    text="  Your results will appear here. <br /> Please enter your texts and click 'Check quality' to begin."
                  >
                    <QualityOutput
                      setDivHeight={setDivHeight}
                      setShowDetailAnalysis={setShowDetailAnalysis}
                      analysisResult={analysisResult}
                      refDiv={refDiv}
                      divHeight={divHeight}
                    />
                  </ToolOutput>
                </div>
                <p className="w-full text-center  text-base mt-12 pb-4 text-[#9C9C9C]">
                  Assisted by AI tools. Inaccuracies may occur.
                </p>
                {analysisResult && (
                  <div className="bg-[#F0F5FF] p-6 rounded-lg mt-6 lg:flex justify-between gap-6">
                    <div>
                      <h2 className="text-2xl font-primary text-[#0A2641] font-bold">
                        Need help translating?
                      </h2>
                      <p className="text-[#424242] pt-3 leading-6 font-base font-opensans ">
                        If your translation needs improvement, reach out to
                        Tomedes for expert translation, editing, and
                        proofreading services.
                      </p>
                    </div>
                    <Link to="/contactus.php">
                      <button className="flex mt-4 md:mt-0 items-center gap-2 bg-[#0A2641] px-6 py-4 text-white rounded-lg">
                        Contact us
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            d="M1 12.75H23M23 12.75L14.75 4.5M23 12.75L14.75 21"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </Link>
                  </div>
                )}
                <p className="mt-8 md:mt-12 text-lg text-center font-opensans leading-[26px]">
                  Enter your source text and its translation, and get detailed
                  feedback on accuracy, fluency, and overall translation quality
                  instantly.
                </p>
              </div>
            {/* </>
          )} */}
        </>
      )}
    </div>
  );
};

export default QualityAnalysis;
